// import React from 'react'
import DetailCard from '../components/DetailCard'
import BusinessFirst from "../assets/icons/businessFirst.png"
import BusinessSecond from "../assets/icons/businessSecond.png"
import { useLocation } from 'react-router-dom';
import { businessDivision } from "../assets/data/businessDivision"
const BusinessDivisionPage = () => {
  const location = useLocation();
  return (
    <div>
      {
        businessDivision?.map((details: any) => details?.pathname === location?.pathname ?
          <div>
            <div className='container flex items-center justify-between gap-[200px] mt-[148px]'>
              <p className='text-[50px] font-bold leading-[55px] border-l-4 border-[#E7BD23] px-4 font-Ultine-NorDem-Medium'>{details?.pageTitle?.split(' ')[0]}<br />{details?.pageTitle?.split(' ')[1]}</p>
              <p className='text-[#505759] text-[16px] font-[400] w-[50%] font-Ultine-Regular'>{details?.pageDescription}</p>
            </div>
            {details?.cardData?.map((values: any) =>
              <DetailCard
                image={values?.cardImage}
                subTitle={values?.subTitle ? values?.subTitle : null}
                title={values?.cardTitle}
                description={values?.cardDescription}
                descriptionSecond={values?.cardDescription2}
              />
            )}

            {/* <div className='mt-[140px] flex items-center justify-center'>
              <DetailCard
                image={BusinessSecond}
                subTitle={true}
                title="Corporate Social Responsibility"
                description="Our CSR tagline is Funding For the Needful. We believe in the values of kindness and social responsibility, which have led us to empower those in need. BK Group played a vital role in providing relief during the devastating 2015 earthquake. We have set up numerous charities and funds to build schools and hospitals that benefit the general public. We take pride in giving back to society through our charitable endeavors, and we ensure that funds are consistently accessible for those who require assistance" />
            </div> */}
          </div>
          : null)
      }

    </div>
  )
}

export default BusinessDivisionPage