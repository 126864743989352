// import React from 'react'
import Logo from "../assets/icons/Logo.svg"
import { BiLogoLinkedin, BiLogoTwitter } from "react-icons/bi"
import { RiFacebookFill } from "react-icons/ri"
import { Link } from "react-router-dom"
// import FotterBackground from "../assets/icons/fotterBg.png"
const data = [
  {
    title: "Quick Links",
    subData: [
      {
        id: 1,
        title: "Home",
        link: "/"
      },
      {
        id: 2,
        title: "About",
        link: "/about"
      },
      {
        id: 3,
        title: "Contact",
        link: "/contact"
      },
      {
        id: 4,
        title: "FAQ",
      },
    ]
  },
  {
    title: "Location",
    subData: [
      {
        id: 1,
        title: "7th floor, Sunrise Bizz",
      },
      {
        id: 2,
        title: "Park, Kathmandu",
      },
      {
        id: 3,
        title: "P: 01-4418049",
      },
      {
        id: 4,
        title: "E: info@bkgroups.org",
      },
    ]
  },
  {
    title: "Follow Us",
    subData: [
      {
        id: 1,
        title: <RiFacebookFill />,
        link: ""
      },
      {
        id: 2,
        title: <BiLogoLinkedin />,
        link: "https://www.linkedin.com/company/bk-group-nepal/about/"
      },
      {
        id: 3,
        title: <BiLogoTwitter />,
        link: ""
      },
    ]
  }
]

const Fotter = () => {
  return (
    <div className='relative'>
      <div className='container flex items-start justify-between-mb-[400px] gap-10 absolute '>
        <div className='flex items-end justify-between flex-1 '>
          <p className='text-[15px] font-Ultine-Regular text-[#cacecf] '>Sign up to our list for<br /> latest updates on our products and services</p>
          <input placeholder='Email' className='p-4 bg-[#f4f4f4] text-[gray] focus:outline-none w-[300px] font-Ultine-Regular' />
          <p className='uppercase text-[15px] text-[#cacecf] border-b-[1px] border-[gray] pb-2 font-Ultine-Regular'>subscribe</p>
        </div>
        <div className='flex flex-col shadow-lg px-5 py-10 gap-8 w-[400px] bg-white z-40 font-Ultine-Regular'>
          <input placeholder='Name' className='border-b border-[lightgray] py-2 focus:outline-none ' />
          <input placeholder='Email' className='border-b border-[lightgray] py-2 focus:outline-none ' />
          <textarea placeholder='Message' className='border-b border-[lightgray] py-2 focus:outline-none ' />
          <div className='flex items-center justify-between  py-10 w-full gap-20 font-Ultine-Regular'>
            <button className='bg-[black] text-[white] px-10 py-3 uppercase hover:opacity-80'>submit</button>
            <p className='text-[12px] text-[#cacecf]'>Question? You can call us at +7(88) 98651245</p>
          </div>
        </div>
      </div>
      {/* <img src={FotterBackground} alt="fotterbg" className='absolute w-[100%] mt-20 ' /> */}
      <div className="content py-16 fotterBackground  text-[white] mt-24 absolute z-30">
        <div className='w-[50%] px-[130px] flex flex-col gap-7 pb-[100px]'>
          <p className='text-[30px] text-[white] pt-20 font-Ultine-Medium'>Let's do some amazing<br /> work together</p>
          <p className="font-Ultine-Regular">We're eager to hear from you! Whether you have questions, feedback, or want to discuss how we can meet your needs, our team is here to assist you. Don't hesitate to reach out via the contact form below, drop us an email, or give us a call.</p>
        </div>
        <div className=' flex items-center justify-center mt-[40px]'>
          <hr className='w-[85%] h-[2px]' />
        </div>
        <div className='container flex items-start justify-between py-10'>
          <img src={Logo} alt='logo' className='cursor-pointer' />
          <div className='flex gap-20'>
            {data?.map((details: any) =>
              <div key={details?.id} className='flex flex-col gap-3'>
                <h1 className='text-[18px] font-Ultine-Medium'>{details?.title}</h1>
                <div className={`flex  ${details?.title === "Follow Us" ? "flex-row gap-5" : "flex-col"}`}>
                  {details?.title === "Follow Us" ? details?.subData?.map((sub_details: any) =>
                    <a href={sub_details?.link}
                      target="_blank" // opens link in a new tab
                      rel="noopener noreferrer">
                      <div key={sub_details?.id} className='cursor-pointer font-Ultine-Regular'>{sub_details?.title}</div>
                    </a>
                  ) : details?.subData?.map((sub_details: any) =>
                    <Link to={sub_details?.link}>
                      <p key={sub_details?.id} className='cursor-pointer font-Ultine-Regular'>{sub_details?.title}</p>
                    </Link>
                  )}
                </div>
              </div>)}
          </div>
        </div>
        <div className=' flex items-center justify-center mt-[40px]'>
          <hr className='w-[85%] h-[2px]' />
        </div>
        <div className=' flex items-center justify-between mt-[40px] container'>
          <p className="font-Ultine-Light text-[12px]">COPYRIGHT 2021 Promomark Technologies</p>
          <span className="font-Ultine-Regular text-[12px]">DESIGNED BY <b>PIXEL CREATIVES</b></span>
        </div>
      </div>
    </div>
  )
}

export default Fotter 