import React, { useEffect, useRef, useState } from 'react';

interface Props {
  description: string;
  title?: string;
  subTitle?: boolean;
  pageTitle?: string;
  image?: any;
  descriptionSecond?: string;
}

const DetailCard = ({ title, description, subTitle, pageTitle, image, descriptionSecond }: Props) => {
  const firstImage = {
    backgroundImage: `url(${image})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: '100% 100%',
  };
  const [dynamicHeight, setDynamicHeight] = useState(0);
  const divRef: any = useRef(null);
  useEffect(() => {
    const updateHeight = () => {
      // Check if the ref is available
      if (divRef.current) {
        // Get the height of the div using offsetHeight
        const height = divRef.current.offsetHeight;
        setDynamicHeight(height);
      }
    };

    // Call the function once to set the initial height
    updateHeight();

    // Add a resize event listener to recalculate the height if the window is resized
    window.addEventListener('resize', updateHeight);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);
  return (
    <div className={`mt-20 mb-20 flex items-center ${subTitle ? 'flex flex-row-reverse container' : 'flex'} `}>
      <div style={firstImage} className={`${descriptionSecond ? "h-[750px]" : "h-[613px]"} w-[708px] flex items-center ${subTitle ? "justify-start" : "justify-end"}`}>
        <div
          className={`bg-[#d59f16] opacity-[0.68] absolute w-[64px] `}
          style={{ height: `${dynamicHeight}px` }}
        >
          {/* No text or content here */}
        </div>
      </div>
      <div ref={divRef} className={` ${subTitle ? 'flex flex-row-reverse ' : 'flex '} items-center w-[618px]`} id='second'>
        <div className='flex flex-col pl-[82px] pr-[98px] py-[120px] shadow-xl h-auto'>
          {pageTitle === 'homepage' ? (
            <p className='text-[#ffb600] text-[15px] font-Ultine-Regular'>CSR PROJECTS</p>
          ) : null}
          <p className='text-[31px] font-bold leading-[34px] text-[#202A2C] font-Ultine-NorDem-Medium'>
            {title?.split(' ')[0]}
            <br /> {title?.split(' ').slice(1).join(' ')}
          </p>
          <p className='text-[14px] text-[#656565] mt-[33px] leading-[20px] font-Ultine-Regular'>
            {description}
            <br />
            {descriptionSecond}
          </p>
          <p className='uppercase hover:bg-[#e7bc1f] hover:text-white border border-[black] text-[10px] px-8 py-3 whitespace-nowrap cursor-pointer w-[130px] mt-10 font-Ultine-Regular'>
            Read more
          </p>
        </div>
      </div>
    </div>
  );
};

export default DetailCard;
