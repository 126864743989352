import Image1 from "../assets/icons/AboutImg1.png";
import Image2 from "../assets/icons/AboutImg2.png";
import Image3 from "../assets/icons/AboutImg3.png";

const data = [
  {
    id: 1,
    title: "Mission Statement",
    description: "Our mission is to establish ourselves as the primary market shareholder in a fiercely competitive industry by providing exceptional customer service...",
    image: Image1,
  },
  {
    id: 2,
    title: "Our Vision",
    description: "Our ultimate goal is not limited to achieving success but rather creating value for the society we serve. We recognize that our existence is interdependent...",
    image: Image2,
  },
  {
    id: 3,
    title: "Our Beliefs",
    description: "We believe in products, brands, and companies that have a positive impact on society. We seek long-term partnerships that focus on serving customers...",
    image: Image3,
  },
];

const AboutImageCard = () => {
  return (
    <div className='flex items-stretch justify-between gap-1 mt-[148px]'>
      {data?.map((details) => (
        <div
          key={details?.id}
          className='flex flex-col py-[200px] px-[92px] gap-20'
          style={{
            backgroundImage: `url(${details.image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            color: 'white',
          }}
        >
          <div className='flex flex-col gap-3'>
            <h1 className='text-[31px] leading-[34px] mb-[23px] font-Ultine-NorDem-Medium'>{details?.title.split(" ")[0]}<br />{details?.title.split(" ")[1]}</h1>
            <p className='text-[16px] leading-[22px] mb-[auto] flex-grow font-Ultine-Light'>{details?.description}</p>
          </div>
          {/* <button className='bg-transparent w-[150px] py-[16px] text-[10px] leading-[15px] text-[white] border border-[white] uppercase hover:bg-[#e7bc1f] hover:text-white'>
            Read more
          </button> */}
        </div>
      ))}
    </div>
  );
};


export default AboutImageCard;
