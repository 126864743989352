// import React from 'react'
// import Banner from '../components/Banner'
import ImageGallery from '../components/ImageGallery'
import DetailCard from '../components/DetailCard'
import Detail from "../assets/icons/detail1.png";
const HomePage = () => {

  return (
    <div>
      <div className='px-[200px] mt-20'>
        <p className='uppercase text-[#ffb600] font-Ultine-Regular'>about us</p>
        <div className='flex items-center justify-between gap-28'>
          <h1 className='text-[32px] leading-8 font-bold font-Ultine-Medium w-[25%]'>With 10 years of experience</h1>
          <p className='w-[50%] text-[15px] text-[#cacecf] font-Ultine-Regular'>BK Group is steered by third-generation leaders who have diversified the business into trading a wide variety of products all over Nepal. We constantly seek business opportunities to build long-standing partnerships based on trust rather than solely on profit.</p>
          <p className='uppercase border border-[black] text-[10px] px-8 py-3 whitespace-nowrap cursor-pointer font-Ultine-Light hover:bg-[#e7bc1f] hover:text-white'>Read more</p>
        </div>
      </div>
      <div className='flex items-center justify-center'>
        <ImageGallery />
      </div>
      <DetailCard
        image={Detail}
        pageTitle="homepage"
        title="We believe in giving back to the community"
        description='Our CSR tagline is "Funding For the Needful." We believe in the values of kindness and social responsibility, which have led us to empower those in need. BK Group played a vital role in providing relief during the devastating 2015 earthquake. We have set up numerous charities and funds to build schools and hospitals that benefit the general public.' />
    </div>
  )
}

export default HomePage