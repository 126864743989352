import BusinessFirst from "../icons/businessFirst.png";
import BusinessSecond from "../icons/businessSecond.png";
import Batteries from "../icons/battries.jpg";
import Distribution from "../icons/distribution.jpg";
import Energy from "../icons/water.jpg";
import FinancialServices from "../icons/finanace.png";
import Stationary from "../icons/stationaries.jpg";
export const businessDivision = [
  {
    id: 1,
    pageTitle: "Distribution",
    pageDescription: "",
    pathname: "/distribution",
    cardData: [
      {
        id: 1,
        cardImage: Distribution,
        cardTitle: "Cavinkare Pvt. Ltd.",
        cardDescription:
          "Cavinkare is a Fast-Moving Consumer Goods (FMCG) conglomerate based in India, specializing in the dairy, snacks, food, beverage, professional care, and salon industries. B.K. Group proudly partners with Cavinkare and serves as the exclusive distributor of the company's Food and Beverage divisions in Nepal",
        cardList: [
          {
            id: 1,
            title: "Food Brands",
            description: [
              "Bhima",
              "Ruchi Magic",
              "Garden",
              "Chinni's Energy Snack",
              "Hema's",
            ],
          },
          {
            id: 2,
            title: "Beverages Brands",
            description: ["Pink of Health, Cocoma", "Maa", "Cavin's Milkshake"],
          },
        ],
      },
      {
        id: 2,
        cardImage: Distribution,
        cardTitle: "ROHTO Pharmaceutical Co. Ltd.",
        subTitle: true,
        cardDescription:
          "ROHTO is a Japanese pharmaceutical company that provides a wide variety of over-the-counter healthcare and personal care products for customers around the world, as well as ophthalmic products, skincare products, and healthcare products. BK Group deals with the distribution of Rohto’s intraocular lenses, pain relief ointment, and eye drops ROHTO’s profound expertise in skincare products has induced BK Group to become the exclusive partner for Nepal across all Rohto products",
      },
      {
        id: 3,
        cardImage: Distribution,
        cardTitle: "Param Dairy Ltd.",
        cardDescription:
          "Param Dairy Limited is one of the reputed manufacturers and exporters of milk and milk products like skim milk powder, full cream milk powder, dairy whitener, paneer, liquid milk, and desi ghee. Trusting the pure intentions instilled by Param Dairy for health, BK Group is involved as a distributor of the dairy segment to satisfy the hygienic and nutritional needs of the people of Nepal. We also deal with bulk supplies of dairy products from Param Dairy in large packages of 25 and 50 kg.",
      },
      {
        id: 4,
        cardImage: Distribution,
        cardTitle: "PIL (Pacific InterLink)",
        subTitle: true,
        cardDescription:
          "Pacific InterLink, based in Kuala Lumpur, Malaysia, promotes the export of various commodities and services from Malaysia to global markets, engaging in the manufacturing, refining, trading, and marketing of consumer products.BK Group has been able to capitalize on its existing network to help PIL distribute its products across Nepal. Some of the most popular soap brand names are:",
        cardList: [
          {
            id: 1,
            title: "",
            description: ["Juliet", "MediTwist", "Zenta"],
          },
        ],
      },
    ],
  },
  {
    id: 2,
    pageTitle: "Batteries",
    pageDescription:
      "BK Group deals with the distribution of maintenance-free lead-acid batteries and VRLA batteries for two-wheelers (Volatic Batteries), four-wheelers (Volta Batteries), inverters, and e-rickshaws with a dealer channel extending all over Nepal. The unique advantage that makes it stand out from other battery distributors is its competitive warranty with all the popular brands.",
    pathname: "/Batteries",
    cardData: [
      {
        id: 1,
        cardImage: Batteries,
        cardTitle: "Volta Batteries",
        cardDescription:
          "Volta batteries are manufactured by Rahimafrooz, the largest lead-acid manufacturer in Bangladesh, and are maintenance-free automotive batteries for four-wheelers. They use state-of-the-art technology in their precision-focused manufacturing facilities to ensure that their batteries deliver high performance and a smooth driving experience.",
      },
      {
        id: 2,
        cardImage: Batteries,
        subTitle: true,
        cardTitle: "Volta Batteries",
        cardDescription:
          "Volta batteries are manufactured by Rahimafrooz, the largest lead-acid manufacturer in Bangladesh, and are maintenance-free automotive batteries for four-wheelers. They use state-of-the-art technology in their precision-focused manufacturing facilities to ensure that their batteries deliver high performance and a smooth driving experience.",
      },
    ],
  },
  {
    id: 3,
    pageTitle: "Stationary",
    pageDescription:
      "BK Group deals with the distribution of maintenance-free lead-acid batteries and VRLA batteries for two-wheelers (Volatic Batteries), four-wheelers (Volta Batteries), inverters, and e-rickshaws with a dealer channel extending all over Nepal. The unique advantage that makes it stand out from other battery distributors is its competitive warranty with all the popular brands.",
    pathname: "/Stationary",
    cardData: [
      {
        id: 1,
        cardImage: Stationary,
        cardTitle: "FujiFilm",
        cardDescription:
          "Fujifilm is a multinational Japanese conglomerate that creates innovative products and delivers effective solutions in the realms of photography, optics, office and medical electronics, biotechnology, and chemicals.",
        cardDescription2:
          "B.K. Group is the authorized distributor of FujiFilm all over Nepal, distributing consumer products such as personal imaging products, digital cameras, instant photosystems, and business products like color paper, photo chemicals, recording media, motion picture products, etc. These products are imported from Japan, India, Malaysia, Singapore, and the Netherlands",
      },
    ],
  },
  {
    id: 4,
    pageTitle: "Manufacturing",
    pageDescription:
      "BK Groups had initiated its manufacturing business with Total Care with the advent of COVID-19. In the next few years, it aims to hold a solid position in the manufacturing business in Nepal.",
    pathname: "/Manufacturing",
    cardData: [
      {
        id: 1,
        cardImage: BusinessFirst,
        cardTitle: "Total Care",
        cardDescription:
          "FA quality multi-purpose, non-toxic disinfectant was introduced in Nepal in 2020 at the back of COVID-19. It is the only locally made, albeit European standard, sanitizer made from molecules approved by well-known global organizations such as WHO, the U.S. FDA, and IATA. Certified by the Ministry of Health and Population Nepal, Total Care kills viruses quickly and has proven to be more effective, especially during COVID times.",
      },
    ],
  },
  {
    id: 5,
    pageTitle: "Renewable Energy",
    pageDescription:
      "We envision Nepal where renewable energy has flourished in every nook and cranny of the country, in every home and industry. Our efforts in the renewable energy sector strengthen Nepal’s aim to achieve universal access to clean, reliable, and affordable renewable energy solutions.",
    pathname: "/RenewableEnergy",
    cardData: [
      {
        id: 1,
        cardImage: Energy,
        cardTitle: "Home Biogas",
        cardDescription:
          "Home biogas promotes sustainability and improves lives by harnessing its expertise in waste treatment and biogas systems. With their groundbreaking, easy-to-use biogas systems, they enable people and businesses around the globe to turn their organic waste into self-made clean energy, on-site.",
        cardDescription2:
          "In Nepal, where the rural areas are comparatively underdeveloped and backward, Homebiogas proves to be a game-changer with independent and reliable access to energy and security. The prefabricated, fully off-the-grid, patent-based systems with modular options are very suitable for rural Nepal, helping the people to have healthier, more efficient, self-resilient, and more sustainable lives. Initially starting in Nuwakot, Bio Mass Power Nepal (Sister concern of BK Group) has already installed more than 500 HomeBiogas systems all over Nepal.",
      },
      {
        id: 2,
        cardImage: Energy,
        subTitle: true,
        cardTitle: "Hydroelectricity",
        cardDescription:
          "Hydroelectricity is the one that has great potential in Nepal and can remove the country's energy dependence on other non-renewable sources of energy. With this in mind, BK Group has invested substantially in significant hydroelectricity projects over the years. Its strategic investments in Mountain Energy Nepal Ltd. for a 42 MW project in Myagdi and in Aadi-Shakti Bidyut Bikash Company Pvt. Ltd. for a 5 MW project in Nuwakot ensure a good direction in the group’s long-term vision of energy sustainability in Nepal.",
      },
    ],
  },
  {
    id: 6,
    pageTitle: "Financial Services",
    pageDescription:
      "At BK Group, we understand the role of financial services in elevating the economy of a country, which in turn helps other industries prosper. We work with the majority of class institutions recognized by Nepal Rastra Bank. We are dedicated to not only strengthening the banking and financial sector but also having beneficial impacts on the lives of people through insurance services. On that account, BK Group is involved in several financial institutions in the capacity of a promoter at all stages of their development. The group has a combined paid-up capital of $150 million in the companies mentioned below, which has soared quickly to a net worth of $650 million within a short period.",
    pathname: "/FinancialServices",
    cardData: [
      {
        id: 1,
        cardImage: FinancialServices,
        cardTitle: "Laxmi Sunrise Bank Ltd.",
        cardDescription:
          "'A' class national bank with authorized capital of Rs. 10,000 million and paid-up capital of Rs. 8,152,555,851 www.sunrisebank.com.np",
      },
      {
        id: 2,
        cardImage: FinancialServices,
        subTitle: true,
        cardTitle: "Reliable Nepal Life Insurance Limited",
        cardDescription:
          "A life insurance company with an authorized capital of Rs. 200 crore and paid-up capital of Rs. 140 crore www.reliablelife.com.np",
      },
      {
        id: 3,
        cardImage: FinancialServices,
        cardTitle: "Manakamana Micro Finance Ltd.",
        cardDescription:
          "Newly established microfinance organization with a registered capital of 200 crores.",
      },
      {
        id: 4,
        cardImage: FinancialServices,
        subTitle: true,
        cardTitle: "PIL (Pacific InterLink)",
        cardDescription:
          "Pacific InterLink, based in Kuala Lumpur, Malaysia, promotes the export of various commodities and services from Malaysia to global markets, engaging in the manufacturing, refining, trading, and marketing of consumer products.BK Group has been able to capitalize on its existing network to help PIL distribute its products across Nepal. Some of the most popular soap brand names are:",
        cardList: [
          {
            id: 1,
            title: "",
            description: ["Juliet", "MediTwist", "Zenta"],
          },
        ],
      },
    ],
  },
];
