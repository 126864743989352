
import ContactCard from './ContactCard'
import { RiFacebookFill } from 'react-icons/ri'
import { BiLogoLinkedin, BiLogoTwitter } from 'react-icons/bi'
import NumberIncrementer from './NumberIncrementer'
import NumberIncrement from './NumberIncrementer'
// import BannerImg from "../assets/icons/banner.png"



const data = [
  {
    id: 1,
    number: 14,
    text: "Years of Experience"
  },
  {
    id: 2,
    number: 10000,
    text: "Employment Opportunities"
  },
  {
    id: 3,
    number: 30,
    text: "Brands Distributed"
  },
]

const icons = [
  {
    id: 1,
    title: <RiFacebookFill className="h-5 w-5" />,
  },
  {
    id: 2,
    title: <BiLogoLinkedin className="h-5 w-5" />,
  },
  {
    id: 3,
    title: <BiLogoTwitter className="h-5 w-5" />,
  },
]

const Banner = ({ title, backgroundImg }: any) => {
  const bannerStyle = title === "homepage" ? backgroundImg : {};
  return (
    <div className={` text-[white]  w-full container ${title === "homepage" ? "py-[150px]" : "py-[200px]"} relative `}
      style={bannerStyle}>
      {title === "homepage" ?
        <h1 className='text-[70px] font-light leading-[70px] font-Din-NextLig pt-[180px]'>Innovating < br /> Nepal</h1>
        :
        title === "about" ?
          <h1 className='text-[70px] font-light leading-[70px] font-Ultine-Light'>About < br /> Us</h1>
          :
          title === "contactus" ?
            <h1 className='text-[70px] font-light leading-[70px] font-Ultine-Light'>Contact < br /> Us</h1>
            :
            title === "business" ?
              <h1 className='text-[70px] font-light leading-[70px] font-Ultine-Light'>Business < br /> Division</h1>
              : null
      }

      {/* <div className={`${title === "homepage" ? "top-[180px] " : "top-[200px] absolute right-32"} absolute right-32`}>
        <ContactCard />
      </div> */}
      {
        title === "homepage" ?
          <>
            <div className='flex items-center justify-between mt-24 mb'>
              <p className='w-[25%] font-Ultine-Regular'>BK Group is a long-standing business enterprise with its roots traced back to the journey of Seth Sagar Mal Modi Devi Khetan. Withstanding the test of time and persevering through economic turmoil, the business has bloomed into one of the country's trading powerhouses, serving as the backbone of the economy.</p>
              <div className='flex items-center gap-20 pr-[10px]'>
                {data?.map((details) =>
                  <div className='flex flex-col gap-3' key={details?.id}>
                    {/* <p className='text-[30px] font-normal whitespace-nowrap font-Ultine-Light'>{details?.number} +</p> */}
                    <NumberIncrement initialValue={5} interval={1500} stopValue={details?.number} />
                    <div className='bg-[white] w-full h-[2px]' />
                    <p className='font-Ultine-Medium w-[50%]'>{details?.text}</p>
                  </div>)}
              </div>
            </div>
            <div className='absolute right-5 bottom-5 flex flex-col gap-5'>
              {icons?.map((details: any) => <div>{details?.title}</div>)}
            </div>
          </> : null
      }

    </div >
  )
}

export default Banner