
import Product2 from "../assets/icons/coffee.jpg"
import Product3 from "../assets/icons/textlies.jpg"
import Product4 from "../assets/icons/water.jpg"
import Product5 from "../assets/icons/footwear.jpg"
import Manufacturing from "../assets/icons/manufacturing.jpg"
import Battries from "../assets/icons/battries.jpg"
import Distibution from "../assets/icons/distribution.jpg"
import FinancialServices from "../assets/icons/finanace.png"
import Stationary from "../assets/icons/stationaries.jpg"
import { Link } from "react-router-dom"
const ImageGallery = () => {
  const Manufac = {
    backgroundImage: `url(${Manufacturing})`, // Set the background image using inline styles
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    // objectfit: 'cover'
  };
  const Station = {
    backgroundImage: `url(${Stationary})`, // Set the background image using inline styles
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    // objectfit: 'cover'
  };
  const battries = {
    backgroundImage: `url(${Battries})`, // Set the background image using inline styles
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    // objectfit: 'cover'
  };
  const Distri = {
    backgroundImage: `url(${Distibution})`, // Set the background image using inline styles
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    // objectfit: 'cover'
  };
  const Financial = {
    backgroundImage: `url(${FinancialServices})`, // Set the background image using inline styles
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    // objectfit: 'cover'
  };
  const secondImage = {
    backgroundImage: `url(${Product3})`, // Set the background image using inline styles
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    // objectFit: 'cover'

  };
  const thirdImage = {
    backgroundImage: `url(${Product4})`, // Set the background image using inline styles
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    // objectFit: 'cover'
  };
  const fourthImage = {
    backgroundImage: `url(${Product5})`, // Set the background image using inline styles
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    // objectFit: 'cover'
  };
  return (
    <div className='px-[120px] mt-32 xl:w-[90%] 3xl'>
      <div className='flex items-center justify-between gap-3 '>
        <p className='border-l-[5px] border-[#ffb600] pl-3 text-[30px] font-bold font-Ultine-NorDem-Medium leading-7'>Our<br /> Businesses</p>
        <p className='w-[60%] text-[15px] text-[#505759]  font-Ultine-Regular'>We explore uncharted territories to discover the latest products, trading a wide variety of products such as edible oils, dairy products, sugar, flour, rice, cosmetics, medicines, and more, catering to millions of Nepali households.  </p>
        {/* <p className='uppercase border border-[black] text-[10px] px-8 py-3 whitespace-nowrap cursor-pointer w-[130px] mt-10 hover:bg-[#e7bc1f] hover:text-white'>Read more</p> */}
        {/* <div className='productMain flex-[0.5] flex flex-col gap-[2px] p-4'>
          <p className='uppercase text-[13px] text-[white] font-bold font-Ultine-NorDem-Light'>BUSINESS</p>
          <p className='capitalize text-[white] text-[22px]  font-Ultine-NorDem-Bold leading-8'>Food Commodities</p>
          <p className='uppercase text-[10px] px-8 py-3 whitespace-nowrap cursor-pointer bg-[white] w-[130px] mt-[100px]
          mb-[20px] opacity-80 hover:bg-[#e7bc1f] hover:text-white'>Read more</p>
        </div> */}
      </div>
      <div className='flex  justify-between gap-3 mt-10'>
        <div className="flex flex-col gap-5 flex-[0.5]">
          <div className="w-full h-full flex flex-col px-[20px] py-[20px] 6xl:py-[60px]" style={Manufac}>
            <div className="flex flex-col gap-1 flex-1">
              <p className='uppercase text-[13px]  text-[white] font-bold font-Ultine-NorDem-Light'>BUSINESS</p>
              <p className='capitalize text-[white] text-[20px] font-Ultine-NorDem-Bold leading-7'>Manufacturing</p>
            </div>
            <Link to="/Manufacturing">
              <p className='uppercase text-[10px] px-8 py-3 whitespace-nowrap cursor-pointer bg-[white] w-[130px] mt-[100px] hover:bg-[#e7bc1f] hover:text-white'>Read more</p>
            </Link>
          </div>
          <div className="flex items-center justify-between w-full gap-3 h-full" >
            <div className='flex flex-col gap-[2px] p-4 w-full 6xl:py-[80px]' style={battries}>
              <p className='uppercase text-[13px]  text-[white] font-bold font-Ultine-NorDem-Light'>BUSINESS</p>
              <p className='capitalize text-[white] text-[20px] font-Ultine-NorDem-Bold w-[10%] leading-7'>Batteries</p>
              <Link to="/Batteries">
                <p className='uppercase text-[10px] px-8 py-3 whitespace-nowrap cursor-pointer bg-[white] w-[130px] mt-[200px] opacity-80 hover:bg-[#e7bc1f] hover:text-white'>Read more</p>
              </Link>
            </div>
            <div className='flex flex-col gap-[2px] w-full p-4 6xl:py-[80px] h-full' style={Distri}>
              <p className='uppercase text-[13px]  text-[white] font-bold font-Ultine-NorDem-Light'>BUSINESS</p>
              <p className='capitalize text-[white] text-[20px] font-Ultine-NorDem-Bold w-[10%] leading-7'>Distribution</p>
              <Link to="/distribution">
                <p className='uppercase text-[10px] px-8 py-3 whitespace-nowrap cursor-pointer bg-[white] w-[130px] mt-[200px] opacity-80 hover:bg-[#e7bc1f] hover:text-white'>Read more</p>
              </Link>
            </div>
          </div>
        </div>
        <div className="flex gap-5 flex-[0.5] flex-col-reverse">
          <div className="w-full h-full flex flex-col px-[20px] py-[20px] 6xl:py-[60px]" style={thirdImage}>
            <div className="flex flex-col gap-1 flex-1">
              <p className='uppercase text-[13px]  text-[white] font-bold font-Ultine-NorDem-Light'>BUSINESS</p>
              <p className='capitalize text-[white] text-[20px] font-Ultine-NorDem-Bold leading-7'>Renewable energy <br /> hydro</p>
            </div>
            <Link to="/RenewableEnergy">
              <p className='uppercase text-[10px] px-8 py-3 whitespace-nowrap cursor-pointer bg-[white] w-[130px] mt-[100px] hover:bg-[#e7bc1f] hover:text-white'>Read more</p>
            </Link>
          </div>
          <div className="flex h-full justify-between w-full gap-3" >
            <div className='flex flex-col gap-[2px] p-4 w-full 6xl:py-[80px]' style={Station}>
              <p className='uppercase text-[13px]  text-[white] font-bold font-Ultine-NorDem-Light'>BUSINESS</p>
              <p className='capitalize text-[white] text-[20px] font-Ultine-NorDem-Bold w-[10%] leading-7'>Stationary</p>
              <Link to="/Stationary">
                <p className='uppercase text-[10px] px-8 py-3 whitespace-nowrap cursor-pointer bg-[white] w-[130px] mt-[200px] opacity-80 hover:bg-[#e7bc1f] hover:text-white'>Read more</p>
              </Link>
            </div>
            <div className='flex flex-col gap-[2px] w-full p-4 6xl:py-[80px]' style={Financial}>
              <p className='uppercase text-[13px]  text-[white] font-bold font-Ultine-NorDem-Light'>BUSINESS</p>
              <p className='capitalize text-[white] text-[20px] font-Ultine-NorDem-Bold w-[10%] leading-7'>Financial Services</p>
              <Link to="/FinancialServices">
                <p className='uppercase text-[10px] px-8 py-3 whitespace-nowrap cursor-pointer bg-[white] w-[130px] mt-[200px] opacity-80 hover:bg-[#e7bc1f] hover:text-white'>Read more</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImageGallery