import { useEffect, useRef, useState } from "react";
import Logo from "../assets/icons/Logo.svg";
import { BiSolidDownArrow } from "react-icons/bi";
import { Link, useLocation } from "react-router-dom";

const data = [
  {
    id: 1,
    title: "Home",
    link: "/",
  },
  {
    id: 2,
    title: "About",
    link: "/about",
  },
  {
    id: 3,
    title: "Business",
    // link: "/businessdivision",
    icon: true,
    subNav: [
      {
        id: 1,
        title: "Batteries",
        link: "/Batteries",
      },
      {
        id: 2,
        title: "Distribution",
        link: "/distribution",
      },
      {
        id: 3,
        title: "Stationary",
        link: "/Stationary",
      },
      {
        id: 4,
        title: "Manufacturing",
        link: "/Manufacturing",
      },
      {
        id: 5,
        title: "Renewable Energy",
        link: "/RenewableEnergy",
      },
      {
        id: 6,
        title: "Financial Services",
        link: "/FinancialServices",
      },
    ],
  },
  {
    id: 4,
    title: "Contact",
    link: "/contact",
  },
];

const Navbar = () => {
  const location = useLocation();
  // const routeWithoutSlash = location.pathname.slice(1);
  const [active, setActive] = useState(location.pathname);
  const [subNavBar, setSubNavBar] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const navbarRef: any = useRef(null); // Reference to the navbar DOM element

  const handleOutsideClick = (event: any) => {
    // Check if the click occurred outside the navbar
    if (navbarRef.current && !navbarRef.current.contains(event.target)) {
      setSubNavBar(false);
    }
  };

  const handleScroll = () => {
    // Check the scroll position to determine the background color
    if (window.scrollY > 0) {
      // If not on the homepage and scrolled down, make the background black
      setScrolling(true);
    } else {
      // Otherwise, keep the background transparent
      setScrolling(false);
    }
  };
  useEffect(() => {
    // Add a scroll event listener to the window
    window.addEventListener("scroll", handleScroll);
    document.addEventListener("click", handleOutsideClick);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [handleScroll]);

  const handleClick = (details: any) => {
    setActive(details?.link);
    // details?.subNav?.length ? setSubNavBar(true) : setSubNavBar(false);
  };
  return (
    <div
      ref={navbarRef}
      className={`fixed top-0 z-50 w-full ${scrolling ? "bg-black" : "bg-transparent"
        } border-b border-[white]`}
    >
      <div className={`w-full container py-5 flex items justify-between`}>
        <Link to="/">
          <img className="cursor-pointer" src={Logo} alt="logo" />
        </Link>
        <div className="flex items-center gap-24 text-[white]">
          {data?.map((details: any) => (
            <div key={details?.id} className="flex flex-col gap-1" onMouseEnter={() => details?.subNav?.length > 0 ? setSubNavBar(true) : setSubNavBar(false)}>
              <div>
                <div
                  onClick={() => {
                    handleClick(details);
                  }}
                  className="flex flex-col items-center justify-center gap-1"
                >
                  <div className="flex items-center gap-2 cursor-pointer relative"
                  >
                    <Link to={details?.link}>
                      <p
                        className={`font-Ultine-Regular  text-[13px] leading-[15px] uppercase ${active === details?.title
                          ? "font-bold"
                          : "font-normal"
                          } hover:font-semibold`}
                      >
                        {details?.title}
                      </p>
                    </Link>
                    {details?.icon ? (
                      <BiSolidDownArrow className="w-[21px] h-[12px]" />
                    ) : null}
                    {details?.subNav?.length && subNavBar ? (
                      <div
                        onMouseLeave={() => setSubNavBar(false)}
                        className="flex flex-col absolute top-10 opacity-95 z-50 bg-[#e7bc1f] p-5 gap-5 w-auto whitespace-nowrap">
                        {details?.subNav?.map((values: any) => (
                          <Link key={values?.id} to={values?.link} onClick={(e) => {
                            e.stopPropagation(); // Stop the click event from propagating
                            setSubNavBar(false);
                          }}>
                            <div
                              className={`font-Ultine-Regular text-[13px] leading-[15px] uppercase ${active === details?.title
                                ? "font-bold"
                                : "font-normal"
                                } `}
                            >
                              <h1>{values?.title}</h1>
                            </div>
                          </Link>
                        ))}
                      </div>
                    ) : null}

                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center">
                {details?.link === active ? (
                  <div className="h-[5px] w-[5px] rounded-full bg-[#E7BC1F] "></div>
                ) : null}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
