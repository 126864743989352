import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useLocation, useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Fotter from '../components/Fotter';
import Banner from '../components/Banner';

interface Props {
  children: React.ReactNode;
  title?: String
  backgroundImg?: any
}


const LandingPageLayout = ({ children, title, backgroundImg }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const firstImage = {
    backgroundImage: `url(${backgroundImg})`, // Set the background image using inline styles
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover'
  };
  React.useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
  }, [navigate]);

  return (

    <div className="relative">
      {/* {title === "homepage" ? */}
      {/* // <div>
        //   <Navbar title={title} />
        //   <Banner title={title} backgroundImg={firstImage} />
        // </div> : */}
      <div style={firstImage}>
        <Navbar />
        <Banner title={title} backgroundImg={firstImage} />
      </div>
      {/* } */}
      <div className="flex flex-col justify-between h-full">
        {children}
        <Fotter />
      </div>
    </div>
    //   </CSSTransition>
    // </TransitionGroup>
  );
};

export default React.memo(LandingPageLayout);
