// NumberIncrement.js
import React from 'react';
import { useSpring, animated } from 'react-spring';

const NumberIncrement = ({ initialValue = 0, stopValue = 10 }: any) => {
  const { number } = useSpring({
    from: { number: initialValue },
    to: { number: stopValue },
    config: { duration: 900 }, // Duration is set to 500 milliseconds (half a second)
    reset: true,
  });

  return <animated.div className="number-increment">{number.interpolate((value: any) => `${Math.floor(value)}+`)}</animated.div>;
};

export default NumberIncrement;
