// import React from 'react'
import ContactDetail from "../assets/icons/contactDetails.png"
import File from "../assets/icons/file.svg"
import Folder from "../assets/icons/folder.svg"
import Globe from "../assets/icons/globe.svg"
import Location from "../assets/icons/location.svg"
import Mail from "../assets/icons/mail.svg"
import Phone from "../assets/icons/phone.svg"
const data = [
  {
    id: 1,
    icon: File,
    title: "+977-1-4522162/ 4517310"
  },
  // {
  //   id: 2,
  //   icon: Folder,
  //   title: "P.O. Box No: 2516"
  // },
  // {
  //   id: 3,
  //   icon: Phone,
  //   title: "Fax: 01-4251413"
  // },
  {
    id: 4,
    icon: Location,
    title: "https://bkgroups.org/"
  },
  {
    id: 5,
    icon: Mail,
    title: "info@bkgroups.org"
  },
  {
    id: 6,
    icon: Globe,
    title: "7th Floor, Sunrise Bizz Park, Charkhal, Dillibazar, Kathmandu, Nepal."
  },

]
const ContactusPage = () => {
  return (
    <div className='container '>
      <div className='flex items-center justify-between gap-[200px] mt-[148px]'>
        <p className='text-[50px] font-bold leading-[55px] border-l-4 border-[#E7BD23] px-4 font-Ultine-NorDem-Medium'>Let's<br />Connect </p>
        <p className='text-[#505759] text-[16px] font-[400] w-[50%] font-Ultine-Regular'>We're eager to hear from you! Whether you have questions, feedback, or want to discuss how we can meet your needs, our team is here to assist you. Don't hesitate to reach out via the contact form below, drop us an email, or give us a call.</p>
      </div>
      <div
        className='grid grid-cols-2 gap-[22px] mt-[70px] mb-[140px]'
        style={{
          backgroundImage: `url(${ContactDetail})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          color: 'white',
          padding: "130px",
        }}
      >
        {data?.map((details: any) =>
          <div key={details?.id} className='flex items-center gap-[17px] whitespace-nowrap'>
            <img src={details?.icon} />
            <p className="text-[21px] leading-[46px] font-Ultine-Regular">{details?.title}</p>
          </div>
        )}
      </div>
    </div>
  )
}

export default ContactusPage