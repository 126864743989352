
import AboutImageCard from '../components/AboutImageCard'
import DetailCard from '../components/DetailCard'
import Soyambhu from "../assets/icons/soyambhu.png"
import CrsImage from "../assets/icons/crsImage.png"
const AboutPage = () => {
  return (
    <div>
      <div className='mt-20'>
        <div className='container flex items-center justify-between gap-[200px]'>
          <p className='text-[50px] font-Ultine-NorDem-Medium leading-[55px] border-l-4 border-[#E7BD23] px-4 '>History</p>
          <p className='text-[#505759] text-[16px] font-[400] w-[50%] font-Ultine-Regular'>BK Group is a long-standing business enterprise with its roots traced back to the journey of Seth Sagar Mal Modi Devi Khetan. Originally hailing from Rampura village in Rajasthan, they embarked on a venture to establish a textile shop in the bustling city of Kathmandu, Nepal. Withstanding test of time & persevering through economic turmoil, the business has bloomed into one of the country's trading powerhouses, serving as the backbone of the economy.</p>
        </div>
        <p className='container text-[#505759] text-[14px] font-[400] mt-[54px] font-Ultine-Regular'>Presently, BK Group is steered by third-generation leaders who have diversified the business into trading a wide variety of products such as edible oils, dairy products, sugar, flour, rice, cosmetics, medicines, and more, catering to millions of Nepali households. The group's foundation is deeply rooted in trust, transparency, and fair trade, established by our ancestors and is preserved to date. We constantly seek business opportunities to build long-standing partnerships based on trust rather than fleeting relationships based solely on profit. Our partnerships with numerous brands and companies allow us to source high-quality products from different parts of the world, meeting the ever-changing demands of Nepali households.
          <br />
          <br />
          We take pride in our ability to adapt to the fast-paced technological advancements of the modern world, equipping ourselves with high-tech machinery to manufacture pharmaceuticals products, printing machinery, automotive, and inverter batteries. Recently, the group has aligned itself with modern sustainability and renewable energy principles by partnering with Homebiogas Israel.
          <br />
          <br />
          As we explore uncharted territories to discover the latest products, we stay true to our roots of ensuring quality assurance and transparency with both clients and vendors. We believe that these foundational values, which have nurtured the giant tree of our family business with hard work and dedication over the generations, will ensure its success in the future.
        </p>
      </div>
      <AboutImageCard />

      <div className='container flex items-center justify-between gap-[200px] mt-[148px]'>
        <p className='text-[50px] font-Ultine-NorDem-Medium leading-[55px] border-l-4 border-[#E7BD23] px-4'>Our<br />Network </p>
        <p className='text-[#505759] text-[16px] font-[400] w-[50%] font-Ultine-Regular'>Our head office is located in Dillibazar, Kathmandu, and houses 200+ employees managing our vast product range across Nepal. Our warehouses, in Kathmandu, and Bhairahawa, serve as the hub of our distribution network. We have over 100 distributors across Nepal, enabling us to serve millions of Nepali consumers through a sophisticated network of trusted channels.</p>
      </div>
      <img className='container mt-10 mb-[148px]' src={Soyambhu} alt="Image" />
      <DetailCard
        image={CrsImage}
        title="Corporate Social Responsibility"
        description='Our CSR tagline is "Funding For the Needful." We believe in the values of kindness and social responsibility, which have led us to empower those in need. BK Group played a vital role in providing relief during the devastating 2015 earthquake. We have set up numerous charities and funds to build schools and hospitals that benefit the general public. We take pride in giving back to society through our charitable endeavors, and we ensure that funds are consistently accessible for those who require assistance.' />
    </div>
  )
}

export default AboutPage