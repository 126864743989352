import { RouterProvider, createBrowserRouter } from "react-router-dom";
import HomePage from "./page/HomePage";
import LandingPageLayout from "./layouts/LandingPageLayout";
import AboutPage from "./page/AboutPage";
import ContactusPage from "./page/ContactusPage";
import BusinessDivisionPage from "./page/BusinessDivisionPage";
import AboutUs from "./assets/icons/AboutUs.png"
import ContactUs from "./assets/icons/contact.png"
import Business from "./assets/icons/business.png"
import HomeImage from "./assets/icons/banner.png"
export default function App() {
  const router = createBrowserRouter([
    {
      path: '',
      element: (
        <LandingPageLayout title="homepage" backgroundImg={HomeImage}>
          <HomePage />
        </LandingPageLayout>
      ),
    },
    {
      path: '/about',
      element: (
        <LandingPageLayout title="about" backgroundImg={AboutUs}>
          <AboutPage />
        </LandingPageLayout>
      ),
    },
    {
      path: '/contact',
      element: (
        <LandingPageLayout title="contactus" backgroundImg={ContactUs}>
          <ContactusPage />
        </LandingPageLayout>
      ),
    },
    {
      path: '/businessdivision',
      element: (
        <LandingPageLayout title="business" backgroundImg={Business}>
          <BusinessDivisionPage />
        </LandingPageLayout>
      ),
    },
    {
      path: '/distribution',
      element: (
        <LandingPageLayout title="business" backgroundImg={Business}>
          <BusinessDivisionPage />
        </LandingPageLayout>
      ),
    },
    {
      path: '/Batteries',
      element: (
        <LandingPageLayout title="business" backgroundImg={Business}>
          <BusinessDivisionPage />
        </LandingPageLayout>
      ),
    }, {
      path: '/Stationary',
      element: (
        <LandingPageLayout title="business" backgroundImg={Business}>
          <BusinessDivisionPage />
        </LandingPageLayout>
      ),
    }, {
      path: '/Manufacturing',
      element: (
        <LandingPageLayout title="business" backgroundImg={Business}>
          <BusinessDivisionPage />
        </LandingPageLayout>
      ),
    }, {
      path: '/RenewableEnergy',
      element: (
        <LandingPageLayout title="business" backgroundImg={Business}>
          <BusinessDivisionPage />
        </LandingPageLayout>
      ),
    }, {
      path: '/FinancialServices',
      element: (
        <LandingPageLayout title="business" backgroundImg={Business}>
          <BusinessDivisionPage />
        </LandingPageLayout>
      ),
    }
  ]);

  return <RouterProvider router={router} />;
}
